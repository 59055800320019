<template>
  <div class="user-menu">
    <ElPopover
      v-model:visible="visibleUserPopover"
      placement="bottom"
      :width="230"
      :show-arrow="false"
      trigger="click"
      popper-class="user-popper"
      v-click-outside="onClickOutside"
    >
      <template #reference>
        <ElAvatar
          :class="['user-menu__avatar', { 'user-menu__avatar--active': visibleUserPopover }]"
          :size="32"
          :src="currentAvatar"
          onerror="this.src='https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"
        />
      </template>
      <div class="user-card">
        <q-avatar size="48px">
          <img
            :src="currentAvatar"
            onerror="this.src='https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"
            style="width: 100%"
            alt="img"

          />
        </q-avatar>
        <div class="user-card__contact">
          <div class="user-card__name">{{ formStore.first_name }}</div>
          <div class="user-card__phone">{{ formattedPhoneNumber }}</div>
        </div>
      </div>

      <ElMenu class="user-menu">
        <ElMenuItem @click="goProfile">
          <BaseIcon name="settings" color="#6B50B2" size="20px" />
          Аккаунт
        </ElMenuItem>
        <ElMenuItem @click="logout">
          <BaseIcon name="logout" color="#6B50B2" size="20px" />
          Выйти
        </ElMenuItem>
      </ElMenu>
    </ElPopover>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '~/store/auth'
import { useFormStore } from '~/store/user'

const router = useRouter()
const formStore = useFormStore()
const { logoutUser } = useAuthStore()

const currentAvatar = computed(() => formStore.$state.userAvatarLink)
const visibleUserPopover = ref(false)

const formattedPhoneNumber = computed(() => {
  let str = formStore.phone
  return `+${str[0]} ${str.substring(1, 4)} ${str.substring(4, 7)}-${str.substring(
    7,
    9,
  )}-${str.substring(9)}`
})

const goProfile = () => {
  router.push({ path: '/profile' })
  visibleUserPopover.value = false
}
const logout = async () => {
  visibleUserPopover.value = false
  await logoutUser()
  await router.push({ path: '/login' })
}

const onClickOutside = () => {
  visibleUserPopover.value = false
}

onMounted(() => {
  formStore.fetchUserData()
  formStore.fetchUserAvatar()
})
</script>

<style lang="scss">
.user-popper {
  --el-popover-padding: 0;
  --el-popover-border-radius: 12px;
  overflow: hidden;
}

.user-card {
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #e9e9f3;
  padding: 12px;
  &__name {
    font-size: 14px;
    font-weight: 500;
    color: var(--slate-800, #2c2933);
  }
  &__phone {
    font-size: 14px;
    font-weight: 400;
    color: var(--slate-400, #aeacbf);
  }
}

.user-menu {
  --el-menu-item-height: 40px;
  .el-menu-item {
    gap: 12px;
  }
  &__avatar {
    border: transparent;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.3s;
    &--active {
      border: 1px solid var(--white, #fff);
      box-shadow: 0px 0px 0px 2px #7247b2;
    }
  }
}
</style>
store/testttt
